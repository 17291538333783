import { h } from "preact";
import styles from "./Flash.css";

const Flash = () => {
  return (
    <div class={styles.flash}>
      The highest Welsh weather cam
      <br />
      south of Snowdonia
    </div>
  );
};

export default Flash;
