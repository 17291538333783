import { h } from "preact";
import styles from "./MenuItem.css";
import { Link } from "preact-router/match";

const MenuItem = (props) => {
  return (
    <Link
      activeClassName={styles.selected}
      class={styles.item}
      href={props.href}
    >
      {props.children}
    </Link>
  );
};

export default MenuItem;
